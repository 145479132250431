import { Typography } from '@/shared';
import {
    ContactPersonStoryblok,
    CtaButtonGroupStoryblok,
    CtaSectionStoryblok,
} from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import { render } from 'storyblok-rich-text-react-renderer';
import { ContentTitleStoryblok } from '../../shared/types/storyblok/storyblok-types';
import ContactPerson from '../ContactPerson/ContactPerson';
import Container from '../Container/Container';
import ContentTitle from '../ContentTitle/ContentTitle';
import CtaButtonGroup from './CtaButtonGroup/CtaButtonGroup';
import styles from './CtaSection.module.scss';
const CtaSection = (props: CtaSectionStoryblok) => {
    const { content, directContact } = props.blok;
    const ContentTitleData = content?.find((item: ContentTitleStoryblok) => item.component === 'contentTitle');
    const ContactPersonData = content?.find((item: ContactPersonStoryblok) => item.component === 'contactPerson');
    const CtaButtonGroupData = content?.find((item: CtaButtonGroupStoryblok) => item.component === 'ctaButtonGroup');
    const { email, phoneNumber } = ContactPersonData?.mind?.content || {};

    return (
        <Container code="standard" className={{ root: styles.root }} blok={props.blok}>
            {ContentTitleData && <ContentTitle blok={ContentTitleData} isDark />}
            {ContactPersonData && <ContactPerson blok={ContactPersonData} isDark />}
            {CtaButtonGroupData && (
                <CtaButtonGroup blok={CtaButtonGroupData} isDark contactSlug={ContactPersonData?.mind?.slug} />
            )}
            {(email || phoneNumber || directContact) && (
                <div className={styles.contact}>
                    {directContact && <div className={styles.directContact}>{render(directContact)}</div>}
                    <div className={styles.info}>
                        {email && (
                            <Typography.Link href={`mailto:${email}`} darkTheme={true} className={styles.linkColor}>
                                {email}
                            </Typography.Link>
                        )}

                        {phoneNumber && (
                            <Typography.Link
                                href={`tel:${phoneNumber}`}
                                darkTheme={true}
                                className={clsx(styles.linkColor, styles.linkSpacing)}
                            >
                                {phoneNumber}
                            </Typography.Link>
                        )}
                    </div>
                </div>
            )}
        </Container>
    );
};

export default CtaSection;
