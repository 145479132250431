import { Typography, getColor } from '@/shared';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { CtaButtonStoryblok, MultilinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import Link from 'next/link';
import { useContext, useMemo } from 'react';
import styles from './CtaButton.module.scss';
type CtaButtonProps = {
    blok?: CtaButtonStoryblok;
    className?: string;
    isDark?: boolean;
    contactSlug?: string;
    customBtn?: {
        text?: string;
        link?: string;
    };
    color?: string;
};

const ButtonContent = ({ text, color, isDark }: { text: string | undefined; color: string; isDark: boolean }) => (
    <div className={styles.btnContent}>
        <Typography.Compressed className={clsx(styles.btnText, isDark && styles.lightText)}>
            {text && text}
        </Typography.Compressed>
        <Icon style={{ color: color, marginLeft: '16px' }} name="arrow_forward" />
    </div>
);

const buildSureUrl = (link: MultilinkStoryblok | undefined): string => {
    if (!link) {
        return '';
    }
    const url = link.cached_url ?? link.url ?? '';
    if (link.linktype === 'url' && !/^https?:\/\//i.test(url)) {
        return `https://${url}`;
    }
    return url;
};

const CtaButton = (props: CtaButtonProps) => {
    const theme = useThemeSection();
    const { text, link, customAction } = props?.blok || {};
    const { isDark = theme === 'Dark' } = props;
    const hexColor = useContext(ColorContext);
    const pageColor = getColor(props.color !== undefined ? 'var(--' + props.color + ')' : hexColor, !isDark);
    const sureUrl = useMemo(() => buildSureUrl(link), [link]);
    const meetUrl = `meet/${props.contactSlug}`;

    return (
        <Link
            id={customAction ? 'meetUrl' : 'cta'}
            href={link?.linktype === 'url' ? sureUrl : customAction ? meetUrl : link?.story.full_slug}
            target={link?.target}
            className={clsx(styles.button, { [styles.dark]: isDark })}
        >
            <ButtonContent text={text} color={pageColor} isDark={isDark} />
        </Link>
    );
};

export default CtaButton;
