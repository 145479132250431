import { IconsId } from '@/shared/types/icons';
import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

import styles from './Icon.module.scss';

type IconProps = HTMLAttributes<HTMLElement> & {
    name: IconsId;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Icon: React.FC<IconProps> = ({ name, className, onClick, ...props }) => (
    <i onClick={onClick} className={clsx(`icon-${name}`, className, styles.icon)} {...props} />
);
