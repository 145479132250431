import Container from '@/components/Container/Container';
import { CtaButtonGroupStoryblok, CtaButtonStoryblok } from '@/shared/types/storyblok/storyblok-types';
import CtaButton from '../CtaButton/CtaButton';
import styles from './CtaButtonGroup.module.scss';

type CtaButtonGroupProps = {
    blok: CtaButtonGroupStoryblok;
    isDark?: boolean;
    contactSlug?: string;
    color?: string;
};

const CtaButtonGroup = (data: CtaButtonGroupProps) => {
    const { gap } = data.blok;

    return (
        <Container
            code="fullpage"
            className={{ wrap: styles.wrapper }}
            style={{ wrap: { paddingTop: data.blok.paddingTop ? data.blok.paddingTop + 'px' : 0 } }}
        >
            {data.blok.buttons?.map((item: CtaButtonStoryblok, index: number) => {
                return (
                    <div
                        key={item._uid}
                        style={{
                            marginTop: index > 0 ? gap + 'px' : 0,
                        }}
                    >
                        <CtaButton blok={item} isDark={data.isDark} contactSlug={data.contactSlug} color={data.color} />
                    </div>
                );
            })}
        </Container>
    );
};

export default CtaButtonGroup;
