import { PropsWithChildren, createContext, useEffect, useReducer } from 'react';
import useStorage from '../helpers/sessionStorage';

// REDUCER
export type UpdateBackgroundColorAction = {
    type: 'update-background-color';
    payload: {
        hexColor: string;
    };
};
const colorReducer = (state: { hexColor: string }, action: UpdateBackgroundColorAction) => {
    if (action.type === 'update-background-color') {
        const { hexColor } = action.payload;
        return { ...state, hexColor };
    } else {
        return state;
    }
};

// CONTEXT
type ColorState = {
    hexColor: string;
};
export const ColorContext = createContext('');

const determineInitColor = (children: any): ColorState => {
    const mainColor = children?.props?.children?.[1]?.props.blok?.pageMainColor;

    const color = mainColor ? `var(--${mainColor})` : '#d94716'; // default to orange-original
    return { hexColor: color };
};

export const ColorProvider = ({ children }: PropsWithChildren) => {
    const initialState = determineInitColor(children!);
    const [state, dispatch] = useReducer(colorReducer, initialState);
    const { setItem } = useStorage();
    useEffect(() => {
        if (initialState.hexColor !== state.hexColor && initialState.hexColor !== '#d94716') {
            dispatch({ type: 'update-background-color', payload: { hexColor: initialState.hexColor } });
            setItem('color', initialState.hexColor);
        }
    }, [initialState.hexColor, setItem, state.hexColor]);

    return <ColorContext.Provider value={state.hexColor}>{children}</ColorContext.Provider>;
};
