import { Typography, getColor } from '@/shared';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useThemeSection } from '@/shared/providers/SectionContext';
import { ContentTitleStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import { useContext } from 'react';
import Container from '../Container/Container';
import styles from './ContentTitle.module.scss';
type ContentTitleProps = {
    blok: ContentTitleStoryblok;
    isDark?: boolean;
};

const ContentTitle = (blok: ContentTitleProps) => {
    const theme = useThemeSection();
    const darkTheme = theme === 'Dark' || blok.isDark || false;
    const hexColor = useContext(ColorContext);

    function FormatText(props: { text: string }) {
        const text = props.text;
        const newText = text.split('\n').map((string: string, index: number) => (
            <span
                style={{ textDecorationColor: getColor(hexColor, darkTheme) }}
                key={index}
                className={styles.underline}
            >
                {string}
                <br />
            </span>
        ));
        return <>{newText}</>;
    }

    return (
        <Container
            blok={blok.blok}
            code="standard"
            className={{
                wrap: clsx(styles.container, blok.blok.pageTitle && styles.mainTitle),
                root: clsx(styles.root, darkTheme && styles.dark),
            }}
        >
            {blok.blok.microTitle && (
                <Typography.H4 darkTheme={darkTheme} className={darkTheme ? styles.microTitleDark : styles.microTitle}>
                    {blok.blok.microTitle}
                </Typography.H4>
            )}
            {blok.blok.title && blok.blok.pageTitle && (
                <Typography.H1 darkTheme={darkTheme}>
                    <FormatText text={blok.blok.title} />
                </Typography.H1>
            )}
            {blok.blok.title && !blok.blok.pageTitle && (
                <Typography.H2 darkTheme={darkTheme} className={darkTheme ? styles.ctaSectionDark : styles.ctaSection}>
                    <FormatText text={blok.blok.title} />
                </Typography.H2>
            )}
            <div style={{ paddingTop: '32px' }}>
                {blok.blok.leadText && (
                    <Typography.P darkTheme={darkTheme} className={darkTheme ? styles.leadTextDark : styles.leadText}>
                        {blok.blok.leadText}
                    </Typography.P>
                )}
            </div>
        </Container>
    );
};

export default ContentTitle;
